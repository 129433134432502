import * as constants from './activitySchema.constants';
import * as activityConstants from '../activity.constants';

export const equals = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_EQUALS,
  [activityConstants.VALUE]: '=='
};

export const notEqual = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_NOT_EQUALS,
  [activityConstants.VALUE]: '!='
};

export const greaterThan = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_GREATER,
  [activityConstants.VALUE]: '>'
};

export const greaterThanOrEqualTo = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_GREATER_EQUALS,
  [activityConstants.VALUE]: constants.OPERATOR_KEY_GREATER_EQUALS_VALUE
};

export const lessThan = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_LESS,
  [activityConstants.VALUE]: constants.OPERATOR_KEY_LESS_THAN_VALUE
};

export const lessThanOrEqualTo = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_LESS_EQUALS,
  [activityConstants.VALUE]: '<='
};

export const oneOf = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_IS_ONE_OF,
  [activityConstants.VALUE]: 'containsAtLeastOneValue'
};

export const notOneOf = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_IS_NOT_ONE_OF,
  [activityConstants.VALUE]: 'doesNotContainAnyOfTheValues'
};

export const dateIs = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_EQUALS,
  [activityConstants.VALUE]: 'dateIsSame'
};

export const dateIsNot = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_NOT_EQUALS,
  [activityConstants.VALUE]: '!dateIsSame'
};

export const dateBefore = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_IS_BEFORE,
  [activityConstants.VALUE]: 'dateIsBefore'
};

export const dateIsOnOrAfter = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_IS_ON_OR_AFTER,
  [activityConstants.VALUE]: 'dateIsOnOrAfter'
};

export const dateTimeIsBetween = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_TIME_IS_BETWEEN,
  [activityConstants.VALUE]: 'dateTimeIsBetween'
};

export const dateTimeIsAfter = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_TIME_IS_AFTER,
  [activityConstants.VALUE]: 'dateTimeIsAfter'
};

export const dateTimeIsBefore = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_TIME_IS_BEFORE,
  [activityConstants.VALUE]: 'dateTimeIsBefore'
};

export const dayOfWeek = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DAY_OF_WEEK,
  [activityConstants.VALUE]: 'dayOfWeek'
};

export const shoppingCartContains = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_CONTAINS,
  [activityConstants.VALUE]: '=='
};

export const shoppingCartNotContain = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_NOT_CONTAINS,
  [activityConstants.VALUE]: constants.OPERATOR_KEY_CONTAINS_NONE
};

export const shoppingCartGreaterThan = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_GREATER_THAN,
  [activityConstants.VALUE]: '>'
};

export const shoppingCartLessThan = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_LESS_THAN,
  [activityConstants.VALUE]: constants.OPERATOR_KEY_LESS_THAN_VALUE
};

export const shoppingCartGreaterOrEqual = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_GREATER_OR_EQUAL,
  [activityConstants.VALUE]: constants.OPERATOR_KEY_GREATER_EQUALS_VALUE
};

export const shoppingCartLessOrEqual = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_SHOPPING_CART_LESS_OR_EQUAL,
  [activityConstants.VALUE]: '<='
};

export const numberIsBetween = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_NUMBER_IS_BETWEEN,
  [activityConstants.VALUE]: 'numberIsBetween'
};

export const allOf = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_CONTAINS_ALL_VALUES,
  [activityConstants.VALUE]: 'containsAllValues'
};

export const containsOperator = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_STRING_CONTAINS,
  [activityConstants.VALUE]: 'stringContains'
};

export const notContains = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_STRING_NOT_CONTAINS,
  [activityConstants.VALUE]: '!stringContains'
};

export const dateIsAfter = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_IS_AFTER,
  [activityConstants.VALUE]: 'dateIsAfter'
};

export const dateIsBetween = {
  [activityConstants.KEY]: constants.OPERATOR_KEY_DATE_IS_BETWEEN,
  [activityConstants.VALUE]: 'dateIsBetween'
};


export const currencyOperators = [greaterThanOrEqualTo, greaterThan, lessThanOrEqualTo, lessThan, equals];
export const numberOperators = [greaterThanOrEqualTo, greaterThan, lessThanOrEqualTo, lessThan, equals];
export const dateOperators = [dateBefore, dateIs, dateIsNot, dateIsOnOrAfter];
export const arrayOperators = [oneOf, notOneOf];
export const booleanOperators = [equals, notEqual];
export const booleanPositiveOnlyOperator = [equals];
export const stringOperatorsEnum = [equals, notEqual];
export const stringOperatorsEmailEnum = [equals, notEqual, containsOperator];
export const shoppingCartPositiveOperators = [shoppingCartGreaterOrEqual, shoppingCartGreaterThan, shoppingCartLessOrEqual, shoppingCartLessThan, shoppingCartContains];
export const shoppingCartOperators = shoppingCartPositiveOperators.concat(shoppingCartNotContain);
