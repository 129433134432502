import _ from 'lodash';
import { call, put, select, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import { fromJS } from 'immutable';
import * as api from './activity.api';
import * as validationUtils from './reducers/utils/validations/validation.utils';
import tracker from '../../common/utils/tracking/tracker';
import * as constants from './activity.constants';
import * as microCampaignConstants from '../MicroCampaign/microCampaign.constants';
import * as errorConstant from '../ErrorMessage/errorMessage.constants';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import * as routerConstants from '../../constants/route.contants';
import * as benefitsConstants from '../Benefits/benefits.constants';
import * as giftConstants from '../Gifts/gifts.constants';
import * as activitySelectors from './activity.selectors';
import * as microCampaignSelectors from '../MicroCampaign/microCampaign.selectors';
import * as appSelectors from '../App/selectors';
import * as appActions from '../App/app.actions';
import * as activityActions from './activity.actions';
import * as utils from './activity.saga.utils';
import * as schemaConstants from './activitySchema/activitySchema.constants';
import * as defaults from './reducers/activity.reducer.defaults';
import { getOneTimeReFilteringCommunicationModel } from './reducers/utils/globalCondition.reducer.utils';
import * as giftsSelectors from '../Gifts/gifts.selectors';
import * as giftsApi from '../Gifts/gifts.api';
import * as queryStringUtils from '../../utils/queryStringUtils';
import * as featuresConstants from '../features.constants';
import * as emailsConstants from '../Emails/emails.constants';
import * as filterConstants from '../FilterMembers/filterMembers.constants';
import * as appSagaUtils from '../App/app.saga.utils';
import * as appApi from '../App/app.api';
import * as appConstants from '../App/app.constants';
import * as dataAndBiSelectors from '../DataAndBI/dataAndBi.selectors';
import * as selectors from '../FilterMembers/filterMembers.selectors';
import * as textUtils from '../../utils/textFormat';


function* getActivity(action) {
  try {
    const data = yield call(api.getActivity, action);
    yield put({ type: constants.GET_ACTIVITY_SUCCESS, data });
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put(appActions.setLoadingState(false));
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getActivity.Fail'] });
    }
  }
}

function* checkActivityNameAvailability({ locationId, name, activityId, activityType }) {
  try {
    const nameAvailable = yield call(api.checkActivityNameAvailability, locationId, activityId, name, activityType);
    yield put({
      type: constants.CHECK_ACTIVITY_NAME_AVAILABILITY_SUCCESS,
      nameAvailable,
      value: name
    });
  } catch (err) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['checkActivityNameAvailability.Fail']
    });
  }
  return yield put({ type: constants.CHECK_ACTIVITY_NAME_AVAILABILITY_DONE });
}

function* validateActivity({ activityState, isDraft }) {
  const activity = activityState.get(constants.DATA);
  const locationId = yield select(appSelectors.getLocationId);
  const activityName = activity.get(constants.NAME);
  const activityId = activity.get(constants.HUB_ID);
  const activityType = activity.get(constants.TYPE);
  const nameAvailable = activityName !== '' ? yield call(api.checkActivityNameAvailability, locationId, activityId, activityName, activityType) : true;
  // TODO - validateActivity should return errors map instead of new state:
  const rootStore = yield select();
  let stateWithErrors = isDraft
    ? validationUtils.validateDraft(activityState)
    : validationUtils.validateActivity(activityState, rootStore);
  if (!nameAvailable) {
    stateWithErrors = validationUtils.handleActivityNameAlreadyInUseError(activityState);
  }
  const validationErrors = stateWithErrors.get(constants.VALIDATION_ERRORS);
  return { validationErrors, nameAvailable };
}

function* forceUpdateActivityName({ locationId, activity, name }) {
  try {
    let index = 0;
    let tempName = name;
    let nameAvailable = yield call(api.checkActivityNameAvailability, locationId, activity.get(constants.HUB_ID), tempName, activity.get(constants.TYPE));
    while (!nameAvailable) {
      index += 1;
      tempName = `${name}(${index})`;
      nameAvailable = yield call(api.checkActivityNameAvailability, locationId, activity.get(constants.HUB_ID), tempName, activity.get(constants.TYPE));
    }
    yield put({
      type: constants.FORCE_UPDATE_NAME_AFTER_VALIDATION,
      value: tempName
    });
  } catch (err) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['forceUpdateActivityName.Fail']
    });
  }
  return yield put({type: constants.CHECK_ACTIVITY_NAME_AVAILABILITY_DONE});
}

function* saveActivity({ isDraft, handleDeleteMemberAction = false, disableReFilteringFlag = false, nonMemberCommunicationFlag = false }) {
  const inProgressParameter = isDraft ? constants.SAVE_AS_DRAFT_IN_PROGRESS : constants.PUBLISH_OR_SAVE_IN_PROGRESS;
  yield put({
    type: constants.SET_PUBLISH_OR_SAVE_IN_PROGRESS,
    [featuresConstants.ACTION_IN_PROGRESS_NAME]: inProgressParameter,
    [featuresConstants.ACTION_IN_PROGRESS_VALUE]: true
  });
  const validationMode = isDraft
    ? constants.ACTIVITY_VALIDATION_MODE_DRAFT :
    constants.ACTIVITY_VALIDATION_MODE_PUBLISH;
  let activityToPublish = {};
  try {
    const activityState = yield select(activitySelectors.getActivityModel);
    if (!isDraft) {
      const isExistingActivity = Boolean(activityState.getIn([constants.DATA, constants.HUB_ID]));
      const publishedBefore = Boolean(activityState.getIn([constants.DATA, constants.SERVER_ID]));
      tracker.onEvent(trackerConstants.EVENT_TYPE_PUBLISH_ACTIVITY, {
        [trackerConstants.ACTIVITY_PUBLISH_ARGS_EXISTING_ACTIVITY]: isExistingActivity,
        [trackerConstants.ACTIVITY_PUBLISH_ARGS_PUBLISHED_BEFORE]: publishedBefore
      });
    }

    const { validationErrors, nameAvailable } = yield validateActivity({ activityState, isDraft });
    if (validationErrors) {
      yield put({
        type: constants.SET_PUBLISH_OR_SAVE_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_NAME]: inProgressParameter,
        [featuresConstants.ACTION_IN_PROGRESS_VALUE]: false
      });
      return yield put({
        type: constants.SET_ACTIVITY_ERRORS,
        [constants.VALIDATION_ERRORS]: validationErrors,
        [constants.ACTIVITY_VALIDATION_MODE]: validationMode,
        [constants.VALIDATION_NAME_TAKEN_FLAG]: !nameAvailable
      });
    }
    const hasDeleteMembersAction = utils.hasDeleteMembersAction(activityState);
    if (handleDeleteMemberAction && hasDeleteMembersAction) {
      return yield put({ type: constants.OPEN_CONFIRM_DELETE_ACTION_DIALOG, [constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN]: [true] });
    }
    activityToPublish = utils.processActivityForPublish(activityState, isDraft);
    const isOneTimeAction = activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_ONE_TIME;
    const filteredPopulation = isOneTimeAction && activityToPublish[constants.ONE_TIME_ACTION_FILTERED_POPULATION] ?
      activityToPublish[constants.ONE_TIME_ACTION_FILTERED_POPULATION] : null;
    const schedulingData = isOneTimeAction && activityToPublish[constants.ONE_TIME_ACTION_SCHEDULING] ?
      activityToPublish[constants.ONE_TIME_ACTION_SCHEDULING] : null;
    let data;
    if (isOneTimeAction) {
      if (!disableReFilteringFlag && !isDraft && filteredPopulation.filterConditions && [constants.MEMBERS_SPECIFIC, constants.MEMBERS_REGISTERED].includes(filteredPopulation.filterConditions.type) && activityToPublish.actions) {
        const existingConditionsList = activityState.getIn([constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_POPULATION_CONDITIONS,
          constants.CONDITIONS, constants.CONDITIONS_LIST]) || fromJS([]);
        const actionsList = activityState.getIn([constants.DATA, constants.ACTIONS, 0, constants.ACTIONS_LIST]);
        const reFilteringModel = getOneTimeReFilteringCommunicationModel(actionsList, existingConditionsList, nonMemberCommunicationFlag);
        if (reFilteringModel.shouldEmailAllowedGlobalConditionBeApplied || reFilteringModel.shouldSMSAllowedGlobalConditionBeApplied ||
            reFilteringModel.shouldPushNotificationGlobalConditionBeApplied || reFilteringModel.shouldConsentGlobalConditionBeApplied) {
          return yield put({
            type: constants.OPEN_RE_FILTER_DIALOG,
            [constants.RE_FILTER_DIALOG_OPEN]: true
          });
        }
      }

      if (!isDraft && (schedulingData && schedulingData.type === constants.SCHEDULE_IMMEDIATE)) {
        const hasSmsActions = utils.hasSmsAction(activityState);
        if (hasSmsActions) {
          const BusinessMonthlySmsMessagesResponse = yield call(() => api.getBusinessMonthlySmsMessagesCount(filteredPopulation.count));
          if (BusinessMonthlySmsMessagesResponse.block) {
            return yield put({
              type: constants.OPEN_BLOCK_SMS_ACTION_DIALOG,
              [constants.BLOCK_SMS_ACTION_DIALOG_OPEN]: [true]
            });
          }
        }
        const hasEmailActions = utils.hasEmailAction(activityState);
        if (hasEmailActions) {
          const BusinessMonthlyEmailMessagesResponse = yield call(() => api.getBusinessMonthlyEmailMessagesCount(filteredPopulation.count));
          if (BusinessMonthlyEmailMessagesResponse.block) {
            return yield put({
              type: constants.OPEN_BLOCK_SMS_ACTION_DIALOG,
              [constants.BLOCK_SMS_ACTION_DIALOG_OPEN]: [true]
            });
          }
        }
      }
      const abTestMode = activityToPublish[constants.AB_TEST_MODE];
      data = yield call(api.saveOneTimeAction, activityToPublish, filteredPopulation, schedulingData, abTestMode);
    } else {
      // if the activity is a migrated gift and already exists in gifts list, make sure you update it and not insert into mongo (put DbId)
      // this can happen if user goes twice to the same url (with the same migrationId)
      if (activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_GIFT && activityToPublish[constants.ACTIVITY_MIGRATED]) {

        const gifts = yield select(giftsSelectors.getGifts);
        const existingGift = gifts
          .find((gift) => gift.get(constants.SERVER_ID) === activityToPublish[constants.SERVER_ID]);
        if (existingGift) {
          activityToPublish[constants.DB_ID] = existingGift.get(constants.DB_ID);
          activityToPublish[constants.HUB_ID] = existingGift.get(constants.HUB_ID);
        }
      }
      data = yield call(api.saveActivity, activityToPublish);
    }

    // Checking for image upload error
    const activitiesToCheckForImageUpload = activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_GIFT ||
      activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_PUNCH_CARD;
    if (activitiesToCheckForImageUpload && data && data.response && data.response.data
      && data.response.data.errorMessage
      && data.response.data.errorMessage.includes('Error creating image resource')) {
      return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['global.image.size.error'] });
    }
    // Checking if oneTime already executed
    if (data==='activity.read.only.info.message'){
      return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [data] });
    }
    if (activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_PROMO_CODE && data.errorType) {
      return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [data.errorMessage] });
    }

    const activityPublishedBefore = activityState.getIn([constants.DATA, constants.SERVER_ID]);
    if (data[constants.HUB_ID]) {
      return yield put({ type: constants.SAVE_ACTIVITY_SUCCESS, data, activityPublishedBefore });
    }
    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail'] });
  } catch (err) {
    tracker.onEvent(trackerConstants.EVENT_TYPE_SAVE_ACTIVITY_SERVER_ERRORS, {
      [trackerConstants.SAVE_ACTIVITY_SERVER_ERRORS_ARGS_ERRORS]: JSON.stringify(err)
    });
    const isPromoCodeActivity = activityToPublish[constants.TYPE] === constants.ACTIVITY_TYPE_PROMO_CODE;
    const errorStatusCode = _.get(err, 'response.status');
    if (isPromoCodeActivity && errorStatusCode === 409) {
      return yield put({ type: constants.PROMO_CODE_BULK_VALUE_TAKEN });
    }
    if (isPromoCodeActivity && errorStatusCode === 400) {
      const errorMessage = _.get(err, 'response.data');
      return yield put({ type: constants.PROMO_CODE_BULK_ERROR, errorMessage });
    }
    if (_.get(err, 'response.data.type') === errorConstant.TRANSACTIONAL_SAVE_FATAL_ERROR) {
      return yield put({
        type: errorConstant.HANDLE_ERROR,
        [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail.fatalError']
      });
    }

    return yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['saveActivity.Fail'] });
  } finally {
    yield put(
      {
        type: constants.SET_PUBLISH_OR_SAVE_IN_PROGRESS,
        [featuresConstants.ACTION_IN_PROGRESS_NAME]: inProgressParameter,
        [featuresConstants.ACTION_IN_PROGRESS_VALUE]: false
      });
  }
}

function* saveActivitySuccess(action) {
  const { [constants.MIGRATION_ID]: migrationId, relatedActivitiesHub1, relatedActivitiesHub2, ...locationSearchParts } = queryStringUtils.parse(location.search);
  const locationSearch = queryStringUtils.stringify(locationSearchParts);
  if (action.data[constants.ACTIVITY_MIGRATED] && migrationId) {
    if (action.data[constants.TYPE] === constants.SERVER_TYPE_GIFT) {
      try {
        yield call(giftsApi.neutralizeHub1Gift, action.data[constants.SERVER_ID]);
      } catch (e) {
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['neutralizeHub1GiftAfterMigration.Fail']
        });
      }
    } else if (action.data[constants.TYPE] === constants.SERVER_TYPE_RULE &&
      (action.data.trigger === schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY
        || action.data.trigger === schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY)) {
      try {
        yield call(api.archiveHub1Activity, `userFieldsAutomations_${migrationId}`, constants.SERVER_TYPE_RULE);
      } catch (e) {
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['archiveHub1ActivityAfterMigration.Fail']
        });
      }
    } else if (action.data[constants.TYPE] === constants.SERVER_TYPE_PUNCH_CARD) {

      try {
        yield call(api.archiveHub1Activity, action.data[constants.SERVER_ID], constants.SERVER_TYPE_PUNCH_CARD, relatedActivitiesHub1, relatedActivitiesHub2);
      } catch (e) {
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['archiveHub1ActivityAfterMigration.Fail']
        });
      }
    }
  }
  const tempOriginActivity = yield select(activitySelectors.getTempOriginActivity);
  if (tempOriginActivity) {
    yield put({ type: benefitsConstants.GET_BENEFITS });
    yield put(push({
      pathname: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_PATHNAME]),
      search: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_SEARCH])
    }));
    yield put({ type: constants.GO_BACK_TO_ACTIVITY_FROM_CREATE_GIFT, data: action.data });
  } else {
    const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);
    yield put(activityActions.highlightActivity(action.data[constants.HUB_ID]));
    switch (action.data[constants.TYPE]) {
      case constants.SERVER_TYPE_GIFT: {
        if (action.data[constants.STATUS] !== constants.ACTIVITY_STATUS_DRAFT) {
          if (action.data.landingPageData && action.data.landingPageData.landingPageActive) {
            yield put(activityActions.OpenLandingPageModal(action.data[constants.LANDING_PAGE_DATA][constants.LANDING_PAGE_SHORTENED_URL], true));
            break;
          }
        }

        yield put(push({ pathname: routerConstants.GIFTS_ROUTE, search: locationSearch }));
        break;
      }
      case constants.SERVER_TYPE_PROMO_CODE: {
        yield put(push({ pathname: routerConstants.PROMO_CODES_ROUTE, search: locationSearch }));
        break;
      }
      default: {
        yield put(push({ pathname: hasBasicPlanPermissions ? routerConstants.BASIC_BUSINESS_CENTER_ROUTE : routerConstants.BUSINESS_CENTER_ROUTE, search: locationSearch }));
      }
    }
    yield delay(4000);
    yield put(activityActions.highlightActivity());
  }
}

function* filterMembersAndValidate(filterConditions, validateConditions) {
  yield put({
    type: constants.SET_FILTERS_IN_PROGRESS,
    [constants.FILTERS_IN_PROGRESS]: true
  });
  let validationErrors = null;
  try {
    if (validateConditions) {
      const activityState = yield select(activitySelectors.getActivityModel);
      const rootState = yield select();
      validationErrors = validationUtils.validateTempFilters(activityState, rootState);
    }
    if (validationErrors && (validationErrors.size > 1 || (validationErrors.size === 1 && validationErrors.getIn([constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_DATE]) !== 'activity.validation.error.scheduled.time.passed'))) {
      yield put({
        type: constants.ADD_TEMP_FILTERS_ERRORS,
        [constants.TEMP_FILTERS_ERRORS]: validationErrors
      });
    } else {
      const locationId = yield select(appSelectors.getLocationId);
      const data = yield call(api.filterMembers, filterConditions, locationId);
      const memberCount = data.filterByConditions.count;
      validationErrors = validationUtils.validateFilteredPopulationCount(memberCount);
      if (validationErrors) {
        yield put({
          type: constants.ADD_TEMP_FILTERS_ERRORS,
          [constants.TEMP_FILTERS_ERRORS]: validationErrors
        });
      } else {
        yield put({ type: constants.FILTER_MEMBERS_SUCCESS, data });
        yield put({
          type: constants.CLOSE_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL,
          isFilteredPopulation: true,
          shouldUpdateModel: true,
        });
      }
    }
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['filterMembers.Fail'] });
    }
  } finally {
    yield put({ type: constants.SET_FILTERS_IN_PROGRESS, [constants.FILTERS_IN_PROGRESS]: false });
  }
}


function* filterMembers() {
  let filterConditions = yield select(activitySelectors.getMembershipGlobalConditionTempModel);
  const isFranchises = yield select(dataAndBiSelectors.getIsUserFranchise);
  if (isFranchises) {
    const franchisesField = yield select(appSelectors.getFranchisesField);
    const getFranchisesByPreferredLocation = franchisesField === schemaConstants.PREFERRED_LOCATION;
    const franchisesCondition = yield getFranchisesAllowedBranchesCondition(getFranchisesByPreferredLocation);
    if (franchisesCondition) {
      const updatedConditions = filterConditions.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]).push(fromJS(franchisesCondition));
      filterConditions = filterConditions.setIn([constants.CONDITIONS, constants.CONDITIONS_LIST], updatedConditions);
      if (filterConditions.get(constants.TYPE) === constants.MEMBERS_REGISTERED) {
        filterConditions = filterConditions.set(constants.TYPE, constants.MEMBERS_SPECIFIC);
      }
    }
  }
  yield* filterMembersAndValidate(filterConditions, true);
}

function* getFranchisesAllowedBranchesCondition(byPreferredLocation) {
  const allLocations = yield select(appSelectors.getLocations);
  const franchisesLocations = yield select(selectors.getFranchisesLocations);
  const locationsBranchesNames = franchisesLocations.map((location) => location.get('branchName'));
  if (!byPreferredLocation && locationsBranchesNames && locationsBranchesNames.size > 0) {
    return {
      conditionKey: schemaConstants.CONTEXT_MEMBERSHIP_HOME_BRANCH_ID,
      operatorKey: constants.IS_ONE_OF,
      conditionValue: locationsBranchesNames,
      dataValueType: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY
    };
  }
  const locationsBranchIds = franchisesLocations.map((location) => location.get('branchId'));
  const franchisesFromAllLocations = allLocations.filter((location) => locationsBranchIds.includes(location.get('id')));
  const franchisesFromAllLocationsNames = franchisesFromAllLocations.map((location) => location.get('location_name'));
  const filteredLocationsBranchesNames = franchisesFromAllLocationsNames.filter((str) => !!textUtils.removeDataUnsupportedCharacters(str));
  const preferredAllowedBranches = filteredLocationsBranchesNames.map((str) => schemaConstants.LOCATIONS_TAG_BORDER + schemaConstants.LOCATIONS_TAG_PREFERRED + str + schemaConstants.LOCATIONS_TAG_BORDER);
  if (preferredAllowedBranches && preferredAllowedBranches.size > 0) {
    return {
      conditionKey: schemaConstants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION,
      operatorKey: constants.IS_ONE_OF,
      conditionValue: preferredAllowedBranches,
      dataValueType: schemaConstants.DATA_VALUE_TYPE_STRING_ARRAY
    };
  }
  return null;
}

function* openFilterModalAndApplyFilter() {
  yield put({
    type: constants.OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL,
    isFilteredPopulation: true,
  });
  const filterConditions = yield select((state) => activitySelectors.getMembershipGlobalConditions(state, true));
  yield* filterMembersAndValidate(filterConditions, false);
}

function* openFilterModalAndApplyCommunicationActionFilter() {
  yield put({
    type: constants.OPEN_GLOBAL_MEMBERSHIP_CONDITIONS_MODAL,
    isFilteredPopulation: true,
    isCommunicationActionReFiltered: true
  });
  yield* filterMembers();
}


function* trackActivityErrors(action) {
  tracker.onEvent(trackerConstants.EVENT_TYPE_VALIDATION_ERRORS, {
    [trackerConstants.VALIDATION_ERRORS_ARGS_VALIDATION_ERRORS]: action[constants.VALIDATION_ERRORS].toJS()
  });
}

function* trackAddCondition(action) {
  const context = action.conditionParent === constants.CASES
    ? trackerConstants.CONTEXT_TYPE_ACTIVITY_CASES
    : trackerConstants.CONTEXT_TYPE_ACTIVITY;
  tracker.onEvent(trackerConstants.EVENT_TYPE_CONDITION_ADD, { [trackerConstants.CONTEXT]: context });
}

function* trackAddGlobalMembershipCondition() {
  tracker.onEvent(trackerConstants.EVENT_TYPE_CONDITION_ADD,
    { [trackerConstants.CONTEXT]: trackerConstants.CONTEXT_TYPE_MEMBERSHIP_GLOBAL_CONDITION });
}

function* getActivityStats(action) {
  try {
    const data = yield call(api.getActivityStats, action[constants.HUB_ID], action.activityType);
    yield put({ type: constants.GET_ACTIVITY_STATS_SUCCESS, data });
  } catch (err) {
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: [`update_${action[constants.TYPE]}.Fail`]
    });
  }
}

function* getAbTestStats(action) {
  try {
    const data = yield call(api.getAbTestStats, action[constants.SERVER_ID]);
    yield put({ type: constants.GET_AB_TEST_STATS_SUCCESS, data });
  } catch (err) {
    yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: ['getAbTestStatistics.Fail'] });
  }
}

function* createGiftFromActivity() {
  return yield put({ type: giftConstants.CREATE_GIFT });
}

function* cancelCreateGiftFromActivity() {
  const tempOriginActivity = yield select(activitySelectors.getTempOriginActivity);
  yield put(push({
    pathname: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_PATHNAME]),
    search: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_SEARCH])
  }));
  return yield put({ type: constants.GO_BACK_TO_ACTIVITY_FROM_CREATE_GIFT });
}

function* createEmailFromActivity() {
  return yield put({ type: emailsConstants.CREATE_EMAIL });
}

function* cancelCreateEmailFromActivity() {
  const tempOriginActivity = yield select(activitySelectors.getTempOriginActivity);
  yield put({ type: constants.GO_BACK_TO_ACTIVITY_FROM_CREATE_EMAIL });
  return yield put(push({
    pathname: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_PATHNAME]),
    search: tempOriginActivity.getIn([constants.RETURN_URL, constants.RETURN_URL_SEARCH])
  }));
}

function* updateTriggerPreValidation(action) {
  const trigger = action.triggerObj.get(constants.NAME);
  const activityData = yield select(activitySelectors.getActivityDataModel);
  const schema = yield select(activitySelectors.getSchemaWithFeatures);
  const canKeepActivityActions = utils.canKeepActionsAndCasesAfterTriggerChange(activityData, schema, trigger);
  if (canKeepActivityActions) {
    return yield put({ type: constants.UPDATE_TRIGGER, trigger, deleteActionsAndCases: false });
  }
  return yield put({ type: constants.CONFIRM_UPDATE_TRIGGER, triggerObj: action.triggerObj });
}


function* handleScheduleTriggerSelected(action) {
  const relevantTriggers = [
    schemaConstants.TRIGGER_MEMBER_HAS_BIRTHDAY,
    schemaConstants.TRIGGER_MEMBER_HAS_ANNIVERSARY,
    schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY,
  ];
  if (!relevantTriggers.includes(action.trigger)) {
    return;
  }
  const businessTimeZone = yield select(appSelectors.getBusinessTimeZone);
  const defaultScheduleValue = defaults.defaultBirthdayOrAnniversarySchedule(businessTimeZone);
  yield put({
    type: constants.UPDATE_TRIGGER_SCHEDULE_VALUE,
    value: defaultScheduleValue
  });
  if (action.trigger !== schemaConstants.TRIGGER_CLUB_MEMBER_ANNIVERSARY) {
    yield put({
      type: constants.SET_BIRTHDAY_OR_ANNIVERSARY_EVENT_SCOPE,
      value: defaults.defaultBirthdayOrAnniversaryEventScope
    });
  } else {
    yield put({
      type: constants.UPDATE_TRIGGER_CLUB_MEMBER_ANNIVERSARY_ONE_TIME_OBJECT,
      value: defaults.defaultClubMemberAnniversaryOneTimeObject
    });
    yield put({
      type: constants.SET_CLUB_MEMBER_ANNIVERSARY_EVENT_SCOPE,
      value: defaults.defaultClubMemberAnniversaryEventScope
    });
  }
}

function* getMigratedActivityData(action) {
  if (action.activityType !== constants.ACTIVITY_TYPE_GIFT) {
    try {
      const data = yield call(api.getMigratedActivityData, action);
      yield put({
        type: constants.GET_MIGRATED_ACTIVITY_DATA_SUCCESS,
        data,
        activityType: action.activityType,
        activityId: action.activityId
      });
    } catch (err) {
      if (_.get(err, 'response.status') !== 403) {
        yield put(appActions.setLoadingState(false));
        yield put({
          type: errorConstant.HANDLE_ERROR,
          [errorConstant.MESSAGES_KEYS]: ['getMigratedGiftData.Fail'],
          [errorConstant.LEAVE_ON_ERROR_CLOSE]: constants.PATH_GIFTS
        });
      }
    }
  }
}

function* uploadImportMembersFile(action) {
  try {
    const data = yield call(api.uploadImportMembersFile, action.fileHandle, action.fileType);
    return yield put({
      type: constants.IMPORT_MEMBERS_FILE_UPLOAD_SUCCESS,
      [constants.UPLOADED_FILE_NAME]: data[constants.UPLOADED_FILE_NAME],
      [constants.UPLOADED_BUCKET_NAME]: data[constants.UPLOADED_BUCKET_NAME],
      [constants.MEDIA_LINK]: data[constants.MEDIA_LINK],
    });
  } catch (err) {
    yield put({ type: constants.IMPORT_MEMBERS_FILE_UPLOAD_ERROR });
    return yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['uploadImportMembersFile.Fail']
    });
  }
}


function* uploadImportCodesBulkFile(action) {
  try {
    const data = yield call(api.uploadImportCodesBulkFile, action.fileHandle);
    return yield put({
      type: constants.IMPORT_CODES_BULK_UPLOAD_SUCCESS,
      [constants.UPLOADED_FILE_NAME]: data[constants.UPLOADED_FILE_NAME],
      [constants.UPLOADED_BUCKET_NAME]: data[constants.UPLOADED_BUCKET_NAME],
      [constants.MEDIA_LINK]: data[constants.MEDIA_LINK],
      bulkIndex: action.bulkIndex
    });
  } catch (err) {
    yield put({ type: constants.IMPORT_CODES_BULK_UPLOAD_ERROR });
    return yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: ['uploadImportCodesBulkFile.Fail']
    });
  }
}

function* setFillteredPopulationError() {
  const locationId = queryStringUtils.getLocationId(location.search);
  yield put(push({ pathname: routerConstants.BUSINESS_CENTER_ROUTE, search: `location_id=${locationId}` }));
  yield put({ type: constants.CLEAR_FILTERED_POPULATION });
  return yield put({
    type: errorConstant.HANDLE_ERROR,
    [errorConstant.MESSAGES_KEYS]: ['filteredPopulationNoFile.Fail']
  });
}

function* requestToEnableGiftLandingPageFeature(action) {
  yield call(api.requestToEnableGiftLandingPageFeature, action);
}
function* requestToEnablePushNotificationWithImageFeature(action) {
  yield call(api.requestToEnablePushNotificationWithImageFeature, action);
}

function* routeToOneTimeAction({ source, filteredPopulation }) {
  const locationId = queryStringUtils.getLocationId(location.search);
  const fileName = filteredPopulation.get(constants.FILTERED_POPULATION_FILE_NAME);
  const memberCount = filteredPopulation.get(constants.FILTERED_POPULATION_COUNT);
  const uriBase = filteredPopulation.get(constants.UPLOADED_BUCKET_NAME);
  const hasBasicPlanPermissions = yield select(appSelectors.hasBasicPlanPermissions);

  yield put(push({
    pathname: hasBasicPlanPermissions ? routerConstants.BASIC_ONETIME_CREATE_ROUTE : routerConstants.ONETIME_CREATE_ROUTE,
    search: `location_id=${locationId}&source=${source}&file_name=${fileName}&member_count=${memberCount}&uri_base=${uriBase}`
  }));
}
function* clearOneTimeActionUrl() {
  const locationId = queryStringUtils.getLocationId(location.search);
  const search = queryStringUtils.getSearchSegment(location.search);
  yield put(push({
    pathname: location.pathname,
    search: `location_id=${locationId}` + (!!search ? `&search=${search}` : '')
  }));
}

function* exportFilteredPopulation({ filteredPopulation }) {
  yield put({
    type: constants.SET_EXPORT_IN_PROGRESS,
    [constants.EXPORT_IN_PROGRESS]: true
  });
  const locationId = yield select(appSelectors.getLocationId);
  const fileName = filteredPopulation.get(constants.FILTERED_POPULATION_FILE_NAME);
  const bucketName = filteredPopulation.get(constants.FILTERED_POPULATION_BUCKET_NAME);
  try {
    const data = yield call(api.exportFilteredPopulation, fileName, bucketName, locationId);
    window.location.href = data.signedUrl;
  } catch (err) {
    if (_.get(err, 'response.status') !== 403) {
      yield put({ type: errorConstant.HANDLE_ERROR, [errorConstant.MESSAGES_KEYS]: [constants.FILTER_MEMBER_FAIL] });
    }
  } finally {
    yield put({
      type: constants.SET_EXPORT_IN_PROGRESS,
      [constants.EXPORT_IN_PROGRESS]: false
    });
  }
}

function* submitActionToCustomer({ customerSearchField, actionType }) {
  if (!customerSearchField) {
    return;
  }
  const searchTrimmed = customerSearchField.trim();
  const type = appSagaUtils.getIdentifierType(searchTrimmed);
  try {
    const membershipKeys = yield call(appApi.queryCustomers, { type, search: searchTrimmed, allowMultiple: true });
    let testToMyselfData = yield select(activitySelectors.getTestToMyselfData);
    if (testToMyselfData.get(constants.VALIDATION_ERRORS) && testToMyselfData.get(constants.VALIDATION_ERRORS).size > 0) {
      return;
    }
    if (actionType != undefined && actionType != null) {
      testToMyselfData = testToMyselfData.setIn([constants.ACTION, constants.ACTION_TYPE], actionType);
    }
    const hubAction = testToMyselfData.get(constants.ACTION).toJS();
    const hubUser = testToMyselfData.get(appConstants.USER).toJS();

    if (hubAction.delay) {
      hubAction.delay = null; // for test to myself we don't need to add delay
    }

    yield all(membershipKeys.map((membershipKey) => call(api.sendAction, { hubAction, membershipKey, hubUser })));
    yield put({
      type: constants.DECLARE_TEST_SUCCEEDED
    });
  } catch (e) {
    let msg = 'sendAction.Fail';
    if (e.response && e.response.data === 'notFound') {
      msg = 'member.not.found';
    }
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: [msg]
    });
  } finally {
    yield put({
      type: constants.DECLARE_TEST_COMPLETED
    });
  }
}
function* submitCommunicationActionToCustomer({ customerSearchField, actionType }) {
  if (!customerSearchField) {
    return;
  }
  const searchTrimmed = customerSearchField.trim();
  const type = appSagaUtils.getIdentifierType(searchTrimmed);
  try {
    const membershipKeys = yield call(appApi.queryCustomers, { type, search: searchTrimmed, allowMultiple: true });
    let testToMyselfData = yield select(microCampaignSelectors.getMicroCampaignTestToMyselfData);
    if (testToMyselfData.get(constants.VALIDATION_ERRORS) && testToMyselfData.get(constants.VALIDATION_ERRORS).size > 0) {
      return;
    }
    if (actionType != undefined && actionType != null) {
      testToMyselfData = testToMyselfData.setIn([constants.ACTION, constants.ACTION_TYPE], actionType);
    }
    const hubAction = testToMyselfData.get(constants.ACTION).toJS();
    const hubUser = testToMyselfData.get(appConstants.USER).toJS();

    if (hubAction.delay) {
      hubAction.delay = null; // for test to myself we don't need to add delay
    }

    yield all(membershipKeys.map((membershipKey) => call(api.sendAction, { hubAction, membershipKey, hubUser })));
    yield put({
      type: constants.DECLARE_TEST_SUCCEEDED
    });
  } catch (e) {
    let msg = 'sendAction.Fail';
    if (e.response && e.response.data === 'notFound') {
      msg = 'member.not.found';
    }
    yield put({
      type: errorConstant.HANDLE_ERROR,
      [errorConstant.MESSAGES_KEYS]: [msg]
    });
  } finally {
    yield put({
      type: constants.DECLARE_TEST_COMPLETED
    });
  }
}

function* getLookerQuery({ fileName, bucketName }) {
  // debounce
  yield delay(1000);
  const queryFileName = fileName.replace('csv', 'query');
  try {
    const query = yield call(api.getLookerQuery, queryFileName, bucketName);
    return yield put({ type: constants.GET_LOOKER_QUERY_SUCCESS, query });
  } catch (e) {
    console.log(`could not get data from looker query file: ${bucketName}/${queryFileName}`);
    return yield put({ type: constants.GET_LOOKER_QUERY_DONE });
  }
}


// sagas listen to dispatched actionsList too (same as reducers)
function* activitySaga() {
  yield [
    takeEvery(constants.GET_ACTIVITY, getActivity),
    takeEvery(constants.SET_ACTIVITY_ERRORS, trackActivityErrors),
    takeEvery(constants.ADD_CONDITION, trackAddCondition),
    takeEvery(constants.ADD_GLOBAL_MEMBERSHIP_CONDITION, trackAddGlobalMembershipCondition),
    takeEvery(constants.SET_FILTERED_POPULATION_ERROR, setFillteredPopulationError),
    takeEvery(filterConstants.CREATE_ONE_TIME_ACTION_ON_FILTERED_POPULATION, routeToOneTimeAction),
    takeEvery(constants.CLEAR_DATA_ON_UNMOUNT, clearOneTimeActionUrl),
    takeLatest(constants.SAVE_ACTIVITY, saveActivity),
    takeLatest(constants.SAVE_ACTIVITY_SUCCESS, saveActivitySuccess),
    takeLatest(constants.CHECK_ACTIVITY_NAME_AVAILABILITY, checkActivityNameAvailability),
    takeLatest(constants.FORCE_UPDATE_NAME, forceUpdateActivityName),
    takeLatest(constants.FILTER_MEMBERS, filterMembers),
    takeLatest(constants.GET_ACTIVITY_STATS, getActivityStats),
    takeLatest(constants.GET_AB_TEST_STATS, getAbTestStats),
    takeLatest(constants.CREATE_GIFT_FROM_ACTIVITY, createGiftFromActivity),
    takeLatest(constants.CANCEL_CREATE_GIFT_FROM_ACTIVITY, cancelCreateGiftFromActivity),
    takeLatest(constants.CREATE_EMAIL_FROM_ACTIVITY, createEmailFromActivity),
    takeLatest(constants.CANCEL_CREATE_EMAIL_FROM_ACTIVITY, cancelCreateEmailFromActivity),
    takeLatest(constants.OPEN_FILTER_MODAL_AND_APPLY_FILTER, openFilterModalAndApplyFilter),
    takeLatest(constants.OPEN_FILTER_MODAL_AND_APPLY_COMMUNICATION_ACTION_FILTER, openFilterModalAndApplyCommunicationActionFilter),
    takeLatest(constants.EXPORT_FILTERED_POPULATION, exportFilteredPopulation),
    takeLatest(constants.UPDATE_TRIGGER_PRE_VALIDATION, updateTriggerPreValidation),
    takeLatest(constants.UPDATE_TRIGGER, handleScheduleTriggerSelected),
    takeLatest(constants.GET_MIGRATED_ACTIVITY_DATA, getMigratedActivityData),
    takeLatest(constants.IMPORT_MEMBERS_FILE_UPLOAD, uploadImportMembersFile),
    takeLatest(constants.IMPORT_CODES_BULK_UPLOAD, uploadImportCodesBulkFile),
    takeLatest(constants.REQUEST_TO_ENABLE_GIFT_LANDING_PAGE_FEATURE, requestToEnableGiftLandingPageFeature),
    takeLatest(constants.REQUEST_TO_ENABLE_PUSH_NOTIFICATION_WITH_IMAGE_FEATURE, requestToEnablePushNotificationWithImageFeature),
    takeLatest(constants.SUBMIT_ACTION_TO_CUSTOMER, submitActionToCustomer),
    takeLatest(microCampaignConstants.SUBMIT_COMMUNICATION_ACTION_TO_CUSTOMER, submitCommunicationActionToCustomer),
    takeLatest(constants.GET_LOOKER_QUERY, getLookerQuery),
  ];
}

export default activitySaga;
